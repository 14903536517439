<template>
  <div
    class="c-modal"
    v-bind:data-modal-status="displayFlg ? 'is-open' : 'is-close'"
  >
    <!-- contents -->
    <div
      class="c-modal__contents"
      v-bind:data-modal-status="displayFlg ? 'is-open' : 'is-close'"
    >
      <div class="c-modal__contents__wrap">
        <span class="c-modal__contents__wrap__closeBtn" @click="onModalClose">
          <font-awesome-icon :icon="['fas', 'times']" />
        </span>
        <template v-if="getModalContent">
          <div class="c-modal__contents__wrap__head">
            <p>{{ getModalContent.data.title }}</p>
          </div>
        </template>
        <div class="c-modal__contents__wrap__body" v-if="getModalContent">
          <div class="c-modal__contents__wrap__body__file">
            <div class="c-modal__contents__wrap__body__file__list">
              <template v-for="(src, index) in getModalContent.data.filePath">
                <div
                  :key="index"
                  class="c-modal__contents__wrap__body__file__list__item"
                  v-if="index == currentDisplayIndex"
                >
                  <template v-if="$route.params.category === 'movie'">
                    <video
                      :src="src"
                      controls
                      autoplay
                      type="video/mp4"
                    ></video>
                  </template>
                  <template v-else-if="$route.params.category === 'code'">
                    <iframe :src="src" frameborder="0"></iframe>
                  </template>
                  <template v-else>
                    <img :src="src" :alt="getModalContent.data.title" />
                  </template>
                </div>
              </template>
              <template v-if="getModalContent.data.filePath.length > 1">
                <div class="c-slide-ctl">
                  <span
                    class="c-slide-ctl__icon is-prev"
                    v-if="currentDisplayIndex >= 1"
                    @click="pagerPrev"
                  >
                    <font-awesome-icon :icon="['fas', 'caret-left']" />
                  </span>
                  <span
                    class="c-slide-ctl__icon is-next"
                    v-if="
                      currentDisplayIndex <
                      getModalContent.data.filePath.length - 1
                    "
                    @click="pagerNext"
                  >
                    <font-awesome-icon :icon="['fas', 'caret-right']" />
                  </span>
                </div>
              </template>
            </div>
          </div>
          <template v-for="(value, key) in getModalContent.data.description">
            <dl class="c-contentDetail" :key="key">
              <dt class="c-contentDetail__title">{{ key }}</dt>
              <dd class="c-contentDetail__explain">{{ value }}</dd>
            </dl>
          </template>
        </div>
      </div>
    </div>
    <!-- overlay -->
    <div
      class="c-modal__overlay"
      @click="onModalClose"
      v-bind:data-modal-status="displayFlg ? 'is-open' : 'is-close'"
    >
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  data() {
    return {
      currentDisplayIndex: 0,
    };
  },
  props: {
    displayFlg: Boolean,
  },
  computed: {
    getDocumtent() {
      return document.getElementsByTagName("body");
    },
    getModalContent() {
      return this.$store.state.itemRef.find((doc) => {
        if (this.$store.state.currentContentId) {
          return doc.id === this.$store.state.currentContentId;
        }
      });
    },
  },
  methods: {
    onModalClose() {
      this.getDocumtent[0].classList.remove("is-modal-open");
      this.$emit("modal-close-flg");
      this.currentDisplayIndex = 0;
    },
    pagerNext() {
      if (
        this.currentDisplayIndex <
        this.getModalContent.data.filePath.length - 1
      ) {
        this.currentDisplayIndex += 1;
      }
    },
    pagerPrev() {
      if (this.currentDisplayIndex >= 1) {
        this.currentDisplayIndex -= 1;
      }
    },
  },
};
</script>

<style lang="scss">
.c-modal {
  position: fixed;
  z-index: 20;
  width: 120vw;
  height: 120vh;
  top: -10vh;
  left: -10vw;

  // animation
  transform-origin: center center;
  transition-delay: 0.3s;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  &[data-modal-status="is-close"] {
    transform: scale(0, 0);
    opacity: 0;
  }
  &[data-modal-status="is-open"] {
    transform: scale(1, 1);
    opacity: 1;
  }
  &__contents {
    // TODO: media query
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    // animation
    transform-origin: center center;
    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    &[data-modal-status="is-close"] {
      transform: translate(-50%, -50%) scale(0, 0);
      opacity: 0;
    }
    &[data-modal-status="is-open"] {
      transform: translate(-50%, -50%) scale(1, 1);
      opacity: 1;
    }
    &__wrap {
      width: 90vw;
      min-width: 300px;
      max-width: 480px;
      height: 90vh;
      padding: $base-padding-size * 2;
      border-radius: 5px;
      background-color: #fff;
      @include createBoxShadow;
      overflow-y: scroll;

      &__head {
      }
      &__body {
        &__file {
          padding: $base-padding-size;
          margin-bottom: $base-padding-size * 2;
          background-color: #444;
          // background-color: #777;
          background-image: url(../../assets/img/bg/patturn.png);
          background-position: center center;
          &__list {
            position: relative;
            width: 100%;
            padding-top: 75%;
            &__item {
              position: absolute;
              top: 0;
              width: 100%;
              padding-top: 75%;
              img,
              video {
                display: block;
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: $base-padding-size;
                background-color: #fff;
                border-radius: 5px;
                border: thin solid rgba(#999, 0.2);
              }
              iframe {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: $base-padding-size;
                background-color: #fff;
                border-radius: 5px;
                border: thin solid rgba(#999, 0.2);
              }
            }
          }
        }
      }
      &__closeBtn {
        display: block;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: 2;
        cursor: pointer;
        color: #444;
        border-radius: 50%;
        border: thick solid;
        background-color: #fff;
        @include createBoxShadow;
        .svg-inline--fa {
          display: block;
          width: 30px;
          height: 30px;
          line-height: 1;
          path {
            fill: currentColor;
          }
        }
      }
    }
  }
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);

    // animation
    transform-origin: center center;
    transition-delay: 0.1s;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    &[data-modal-status="is-close"] {
      opacity: 0;
    }
    &[data-modal-status="is-open"] {
      opacity: 1;
    }
  }
}

.c-slide-ctl {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  &__icon {
    display: block;
    position: absolute;
    top: 0;
    transform: translate(0, -50%);
    font-size: 4rem;
    cursor: pointer;
    .svg-inline--fa {
      transform-origin: center center;
      transition-delay: 0;
      transition-duration: 0.2s;
      transition-timing-function: ease-out;
      &:hover {
        transform: scale(1.2, 1.2);
      }
      path {
        fill: #444;
      }
    }
    &.is-prev {
      left: -3rem;
    }
    &.is-next {
      right: -3rem;
    }
  }
}
.c-contentDetail {
  font-size: 0;
  // padding: 10px;
  &:not(:last-of-type) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: thin solid #ddd;
  }
  &__title {
    display: inline-block;
    vertical-align: top;
    min-width: 80px;
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.4rem;
    border-radius: 3px;
    box-shadow: 0 2px 2px rgba(#000, 0.2);
    background-color: #444;
    color: #fff;
  }
  &__explain {
    vertical-align: top;
    font-size: 1.4rem;
  }
}
</style>
