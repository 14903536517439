<template>
  <article class="list">
    <!-- v-forはwrapごと -->
    <section
      class="c-tonbo__wrap"
      v-for="(item, index) in sortedItemList"
      v-bind:key="index"
    >
      <div class="c-tonbo">
        <div class="c-tonbo__inner">
          <div class="c-tonbo__inner__head">
            <Postit
              :category="$route.params.category"
              :subCategory="item.subCategory"
            />
          </div>
          <div class="c-tonbo__inner__body">
            <div class="c-thumbnail__wrap">
              <Thumbnail
                @modal-open-flg="modalOpen"
                :subCategoryItem="item.docs"
              />
            </div>
          </div>
          <div class="p-list__foot"></div>
        </div>
      </div>
    </section>
    <Modal @modal-close-flg="modalClose" :display-flg="displayFlg.modal" />
  </article>
</template>

<script>
// firebase
import db from "@/plugins/firestore";
import storage from "@/plugins/cloudStorage";
// component
import Thumbnail from "@/components/objects/Thumbnail.vue";
import Postit from "@/components/objects/Postit.vue";
import Modal from "@/components/objects/Modal.vue";

export default {
  name: "tombo",
  components: {
    Postit,
    Thumbnail,
    Modal,
  },
  data() {
    return {
      dummyCategoryImgLists: 1,
      displayFlg: {
        modal: false,
      },
      sortedItemList: [],
      subCategoryList: [],
    };
  },
  computed: {},
  watch: {
    $route(to) {
      this.refProductList(to.params.category);
    },
  },
  methods: {
    refProductList(paramsCategory) {
      let itemList = [];
      db.collection(paramsCategory)
        .orderBy("subCategory", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            itemList.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          this.refStorageFiles(itemList);
          this.refModalFiles(itemList);
          this.$store.commit("setItemRef", itemList);
          let categoryList = this.getSubCategoryList(itemList);
          this.sortedItemList = this.sortProductList(itemList, categoryList);
          // this.$store.commit('setItemRef', [])
        });
    },
    getSubCategoryList(argItemList) {
      let array = [];
      argItemList.forEach((item) => {
        array.push(item.data.subCategory);
      });
      argItemList = array.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      return argItemList;
    },
    sortProductList(argItemList, argCategoryList) {
      let sortedDocs = [];
      argCategoryList.forEach((key) => {
        let value = argItemList.filter((doc) => doc.data.subCategory === key);
        sortedDocs.push({
          subCategory: key,
          docs: value,
        });
      });
      return sortedDocs;
    },
    refStorageFiles(argItemList) {
      argItemList.forEach((doc) => {
        if (doc.data.thumbnailPath) {
          storage
            .ref()
            .child(doc.data.thumbnailPath)
            .getDownloadURL()
            .then((url) => {
              doc.data.thumbnailPath = url;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          storage
            .ref()
            .child("/other/thumbnail/prepare_img.png")
            .getDownloadURL()
            .then((url) => {
              doc.data.thumbnailPath = url;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    refModalFiles(argItemList) {
      argItemList.forEach((doc) => {
        doc.data.filePath.forEach((item, index) => {
          if (item) {
            storage
              .ref()
              .child(item)
              .getDownloadURL()
              .then((url) => {
                doc.data.filePath.splice(index, 1, url);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            doc.data.filePath.splice(index, 1, "dummyPath");
          }
        });
      });
    },
    modalOpen() {
      this.displayFlg.modal = true;
    },
    modalClose() {
      this.displayFlg.modal = false;
    },
  },

  created() {
    this.refProductList(this.$route.params.category);
  },
  mounted() {},
};
</script>

<style lang="scss">
.c-tonbo__wrap {
  &:not(:last-of-type) {
    margin-bottom: $base-padding-size * 4;
  }
}
.c-tonbo {
  padding: 26px 27px;
  background-image: url(../../assets/img/bg/tonbo-top-left.gif),
    url(../../assets/img/bg/tonbo-top-right.gif),
    url(../../assets/img/bg/tonbo-bottom-right.gif),
    url(../../assets/img/bg/tonbo-bottom-left.gif),
    url(../../assets/img/bg/tonbo-top-center.gif),
    url(../../assets/img/bg/tonbo-center-right.gif),
    url(../../assets/img/bg/tonbo-bottom-center.gif),
    url(../../assets/img/bg/tonbo-center-left.gif);
  background-position: top left, top right, bottom right, bottom left,
    top center, center right, bottom center, center left;
  background-repeat: no-repeat;
  &__inner {
    padding: $base-padding-size * 2;
    background-color: #777;
    background-image: url(../../assets/img/bg/patturn.png);
    background-position: center center;
    // &__head {
    //   margin-bottom: $base-padding-size;
    // }
    // &__body {
    //   margin-bottom: $base-padding-size;
    // }
    // &__foot {
    // }
  }
}
</style>
