<template>
  <div class="thumbnail">
    <ul class="c-thumbnail">
      <li
        class="c-thumbnail__item"
        v-for="(doc, index) in subCategoryItem"
        :key="index"
      >
        <div class="c-thumbnail__item__img">
          <a class="c-thumbnail__item__img__link" @click="onModalOpen(doc.id)">
            <img :src="doc.data.thumbnailPath" :alt="doc.data.title" />
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// firebase
export default {
  name: "thumbnail",
  components: {},
  data() {
    return {};
  },
  props: {
    subCategoryItem: Array,
  },
  computed: {
    getDocumtent() {
      return document.getElementsByTagName("body");
    },
  },
  methods: {
    onModalOpen(id) {
      this.getDocumtent[0].classList.add("is-modal-open");
      this.$emit("modal-open-flg");
      this.$store.commit("setCurrentContentId", id);
    },
  },
};
</script>

<style lang="scss">
.c-thumbnail {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  &__item {
    flex-grow: 0;
    flex-shrink: 2;
    flex-basis: calc(100% / 4);
    padding: $base-padding-size;
    @media (max-width: 320px) {
      /* under 320px */
      flex-basis: calc(100% / 1);
    }
    @media (min-width: 321px) and (max-width: 480px) {
      /* over 321px and under 480px */
      flex-basis: calc(100% / 2);
    }
    @media (min-width: 481px) and (max-width: 768px) {
      /* over 481px and under 768px */
      flex-basis: calc(100% / 3);
    }
    &__img {
      width: 100%;
      &__link {
        display: block;
        padding: $base-padding-size;
        border-radius: 3px;
        background-color: white;
        box-shadow: 0 2px 5px rgba(#000, 0.3);
        transform: scale(0, 0);
        animation: lazyImg 0.8s linear 1s forwards;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
}
@keyframes lazyImg {
  0% {
    transform: scale(0, 0);
  }
  85% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
