<template>
  <div class="p-contents">
    <Tombo />
  </div>
</template>

<script>
import Tombo from "@/components/objects/Tombo.vue";
export default {
  name: "contents",
  components: {
    Tombo,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
