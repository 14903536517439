import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB7mPNUV5nfj1fraRbAQcynUxM3R8HsQvU",
  authDomain: "fb-portfolio-7575a.firebaseapp.com",
  databaseURL: "https://fb-portfolio-7575a.firebaseio.com",
  projectId: "fb-portfolio-7575a",
  storageBucket: "fb-portfolio-7575a.appspot.com",
  messagingSenderId: "11948682197",
  appId: "1:11948682197:web:90bffc219acc2f48d8ab5c",
};
export default firebase.initializeApp(firebaseConfig);
