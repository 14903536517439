<template>
  <transition>
    <div class="c-postit">
      <h2 :class="'c-postit__area ' + categoryData.categoryClass">
        <font-awesome-icon :icon="categoryData.icon" />
        <span class="c-postit__area__txt">{{ subCategory }}</span>
      </h2>
    </div>
  </transition>
</template>

<script>
import CategoryList from "@/data/categoryList";
export default {
  name: "postit",
  data() {
    return {};
  },
  props: {
    category: String,
    subCategory: String,
  },
  computed: {
    categoryData() {
      return CategoryList.find((item) => {
        return item.name.toLowerCase() === this.category;
      });
    },
  },
  updated() {},
};
</script>

<style lang="scss">
.c-postit {
  position: relative;
  max-width: 300px;
  &__area {
    position: absolute;
    top: -30px;
    left: -30px;
    z-index: 10;
    transform: rotate(352deg);
    padding: {
      top: $base-padding-size;
      right: $base-padding-size;
      bottom: $base-padding-size;
      left: $base-padding-size * 4;
    }
    font-size: 2rem;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(#000000, 0.3);
    .svg-inline--fa {
      position: relative;
      left: -2.5rem;
    }
    @each $key, $color in $colors {
      &.is-#{$key} {
        background-image: linear-gradient(
          92deg,
          darken($color, 15%) 0%,
          darken($color, 10%) 0.5%,
          darken($color, 5%) 13%,
          $color 16%
        );
      }
      @if $key == movie {
        &.is-#{$key} {
          path {
            fill: map-get($color_font, black);
          }
          span {
            color: map-get($color_font, black);
          }
        }
      } @else if $key == shiro {
        &.is-#{$key} {
          border: $base-border-width solid;
          path {
            fill: map-get($color_font, black);
          }
          span {
            color: map-get($color_font, black);
          }
        }
      } @else {
        &.is-#{$key} {
          path {
            fill: map-get($color_font, white);
          }
          span {
            color: map-get($color_font, white);
          }
        }
      }
    }
    // &__txt {
    // }
  }
}
</style>
